<template>
  <v-row class="mt-4" v-if="form.documents.length !== detail.data.documents.length">
    <v-col :lg="12" :sm="12" :md="12">
      <h4 class="app-stepper-title">Faça o upload dos arquivos</h4>
    </v-col>
    <v-col
      :lg="12"
      :sm="12"
      :md="12"
      v-for="(doc, index) in form.documents"
      :key="doc.typeDocumentKey"
    >
      <h4 class="typeDocument-title">{{ doc.typeDocumentName }}</h4>
      <p class="typeDocument-subtitle">{{ doc.typeDocumentDescription }}</p>
      <UploadFile v-model="doc.file" :nameInput="index" @change="onUpload($event, doc)" />
      <v-list subheader two-line v-if="doc.file">
        <v-list-item :key="index" :dense="true" :selectable="true">
          <v-list-item-avatar>
            <v-icon class="red darken-2" dark style="color:#fff !important;">picture_as_pdf</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="doc.filename"></v-list-item-title>

            <v-list-item-subtitle v-text="`Tipo: ${doc.type} Tamanho: ${fileSize(doc.size)}`"></v-list-item-subtitle>

            <!-- <v-list-item-subtitle>
              <v-btn
                type="button"
                @click="remove(doc)"
                title="Remover arquivo"
                text
                color="red"
                small
              >
                Excluir
              </v-btn>
            </v-list-item-subtitle>-->
          </v-list-item-content>
          <v-list-item-action>
            <v-icon size="24" color="#78e5d5" v-if="doc.uploaded">check_circle</v-icon>
            <v-icon size="24" color="#e57878" v-if="doc.error">info</v-icon>
            <v-progress-circular
              size="21"
              :width="3"
              :value="doc.progress"
              v-if="!doc.error && !doc.uploaded"
            ></v-progress-circular>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider class="mt-6"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UploadFile from "@/components/UploadFile";
import { fileSize } from "@/util/format-file";

export default {
  name: "PedidoDocumentosForm",
  components: {
    UploadFile
  },
  data: () => ({
    documentPagination: {
      input: {
        name: null,
        typeSolicitationId: null
      },
      inputPage: {
        page: 1,
        size: 20,
        order: "name",
        descingOrder: false
      }
    }
  }),
  methods: {
    fileSize(v) {
      return fileSize(v);
    },
    onUpload(event, doc) {
      this.uploadDocument({
        currentDocument: doc,
        file: event.target.files[0]
      });
    },
    findAllTypeDocuments() {
      this.documentPagination.input.typeSolicitationId = Number(
        this.detail.data.typeSolicitation.id
      );
      this.pageTipoDocumento(this.documentPagination);
    },
    ...mapActions("pedido", {
      pageTipoDocumento: "findTypesDocuments"
    }),
    ...mapActions("pedido", {
      uploadDocument: "uploadDocument"
    }),
    ...mapActions("pedido", { getPedido: "SHOW" })
  },
  computed: {
    ...mapState("pedido", ["detail"]),
    ...mapState("pedido", ["form"]),
    hasGoNextStep: function() {
      return this.form.documents.every(d => d.uploaded && !d.error);
    },
    currentSolicitationType: function() {
      return this.detail.data ? this.detail.data.typeSolicitation.id : null;
    }
  },
  watch: {
    currentSolicitationType: function(newValue, oldValue) {
      if (newValue) {
        this.documentPagination.input.typeSolicitationId = newValue;
        this.findAllTypeDocuments();
      }
    },
    hasGoNextStep: function(newValue, oldValue) {
      if (newValue) {
        this.getPedido(this.detail.data.id);
      }
    }
  },
  mounted() {
    this.findAllTypeDocuments();
  }
};
</script>

<template>
  <section>
    <p class="mt-3 bold">Chave Pix: sistransce@sistransce.com.br</p>
    <p class="mt-3">
      Atenção: Após realizar o pagamento via Pix enviar o comprovante e número
      do pedido para o email
      <a href="mailto:atendimento@sistransce.com.br"
        >atendimento@sistransce.com.br</a
      >
    </p>
    <v-btn
      depressed
      color="success"
      id="form-checkout__submit"
      type="button"
      block
      @click.prevent="doPayment()"
      >Finalizar Pagamento</v-btn
    >
  </section>
</template>

<script>
import { DEFAULT_EMAIL_PAYMENT } from "@/commons/config";
export default {
  name: "PaymentPix",
  props: {
    paymentCartaoIndendificationNumber: {
      type: String,
      required: true,
      default: "",
    },
    paymentCartaoIndendificationValue: {
      type: Number,
      required: true,
      default: 0,
    },
    idPedidos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    doPayment() {
      const formPaymets = {
        kind: "add",
        content: {
          solicitation_ids: this.idPedidos,
          type: "pix",
          payment_data: {},
        },
      };
      this.$emit("doPayment", formPaymets);
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>

<template>
  <article class="cart-detail" v-if="paymentDetailProducts.length > 0">
    <p class="text-right font-weight-medium">
      Grupo:
     <span class="text-capitalize"> {{ paymentDetailProducts[0].group || "" }}</span>
    </p>
    <section
      class="cart-detail-item d-flex flex-column"
      v-for="(payments, index) in paymentDetailProducts"
      :key="index"
    >
      <h3 class="text-left">
        <em> Placa: {{ payments.placa }}</em>
      </h3>
      <h3 class="text-left">
        {{ payments.typeSolicitation.name }}
      </h3>
      <h4 class="text-right">
        <em>{{ payments.typeSolicitation.value | currency }}</em>
      </h4>
      <router-link
        :to="{ name: 'pedidos-detail', params: { id: payments.id } }"
        title="Visualizar Pedido"
        target="_blank"
        v-if="payments.id"
      >
        Visualizar pedido
      </router-link>
      <v-divider></v-divider>
    </section>
    <section class="cart-detail-item d-flex">
      <h3 class="text-left"><em>Total</em></h3>
      <h3 class="text-right">
        <em>{{ total | currency }}</em>
      </h3>
    </section>
  </article>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PaymentGroupDetalhe",
  props: {
    paymentDetailProducts: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    valueInitial: 0,
  }),
  computed: {
    ...mapState("autovistoria", ["form"]),
  },
  methods: {
    sumTotal() {
      const totalValue = this.paymentDetailProducts.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.typeSolicitation.value),
        0
      );
      return totalValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-detail {
  &-item {
    display: flex;
    padding: 10px 0px;
    .text-left {
      width: 100%;
      margin: 0px;
      font-size: 15px !important;
      em {
        font-style: normal;
        display: block;
        font-weight: 500;
      }
      span {
        font-size: 13px !important;
      }
    }
    .text-right {
      margin: 0px;
      font-size: 15px !important;
      align-content: end;
      width: 100%;
      padding-bottom: 0.5rem;
      em {
        font-style: normal;
        font-size: 15px !important;
        display: block;
        font-weight: 500;
        text-align: end;
      }
    }
  }
  .total {
    h3 {
      margin: 0px;
      font-size: 15px !important;
      align-content: end;
      width: 100%;
      padding-bottom: 0.5rem;
    }
  }
}
</style>

<template>
  <v-row>
    <v-col :lg="5" :md="5">
      <v-card class="app-card app-card--custom">
        <v-card-title class="app-card-title mb-0">
          <h4 class="text">
            <v-icon>shopping_basket</v-icon>
            <span>Detalhes</span>
          </h4>
        </v-card-title>
        <v-card-text class="app-card-content mt-3">
          <article class="cart-detail">
            <section class="cart-detail-item">
              <h3 class="text-left">
                <em>{{detail.data.typeSolicitation.name}}</em>
                <!-- <span>Veículo: {{detail.data.placa}}</span> -->
              </h3>
              <h4 class="text-right">
                <em>{{detail.data.typeSolicitation.value | currency}}</em>
              </h4>
            </section>
          </article>
          <section class="loading" v-if="detail.isLoading">
            <v-progress-circular indeterminate color="success"></v-progress-circular>
            <span>Enviando informações ...</span>
          </section>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :lg="7" :md="7">
      <v-expansion-panels accordion class="app-expansion" v-model="panel">
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <v-icon>credit_card</v-icon>
              <span>Pagamento Cartão</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CredtForm />
            <section class="loading" v-if="detail.isLoading">
              <v-progress-circular indeterminate color="success"></v-progress-circular>
              <span>Enviando informações ...</span>
            </section>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <v-icon>request_quote</v-icon>
              <span>Pagamento Boleto</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-3">Atenção: O pagamento via boleto demora até 48h úteis para ser debitado.</p>
            <v-btn
              depressed
              color="success"
              id="form-checkout__submit"
              type="button"
              block
              @click.prevent="doPayment('bolbradesco')"
            >Finalizar Pagamento</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <img src="../../../assets/images/pix.svg" alt="img-pix" width="22px" height="22px">
              <span>Pagamento Pix</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <section>
              <p class="mt-3 bold">Chave Pix: sistransce@sistransce.com.br</p>
              <p class="mt-3">
                Atenção: Após realizar o pagamento via Pix enviar o comprovante e número do pedido para o email <a href="mailto:atendimento@sistransce.com.br">atendimento@sistransce.com.br</a>
              </p>
              <v-btn
                depressed
                color="success"
                id="form-checkout__submit"
                type="button"
                block
                @click.prevent="doPayment('pix')"
                >Finalizar Pagamento</v-btn
              >
            </section>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CredtForm from "./CreditForm";
import { DEFAULT_EMAIL_PAYMENT } from "../../../commons/config";

export default {
  name: "PagamentosForm",
  components: {
    CredtForm
  },
  data: () => ({
    panel: [0]
  }),
  methods: {
    doPayment(paymentMethodId) {
      const paymentData = {
        amount: this.detail.data.typeSolicitation.value,
        cardholderEmail: DEFAULT_EMAIL_PAYMENT,
        identificationNumber: this.detail.data.company.cnpj,
        identificationType: "CNPJ",
        installments: null,
        issuerId: "",
        merchantAccountId: null,
        paymentMethodId: paymentMethodId,
        processingMode: null,
        token: ""
      };

      this.createPayment(paymentData);
    },
    ...mapActions("pedido", { createPayment: "CREATE_PAYMENT" })
  },
  computed: {
    ...mapState("pedido", ["form"]),
    ...mapState("pedido", ["detail"])
  }
};
</script>
<style lang="scss" scoped>
.payment-type {
  text-align: center;
}
.payment-radio {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 0px !important;
}
.v-input--selection-controls__input {
  margin: 5px 0px !important;
}
.cart-detail {
  &-item {
    //border-bottom: solid 1px #ddd;
    display: flex;
    padding: 10px 0px;
    .text-left {
      width: 80%;
      margin: 0px;
      font-size: 15px !important;
      em {
        font-style: normal;
        display: block;
        font-weight: 500;
      }
      span {
        font-size: 13px !important;
      }
    }
    .text-right {
      width: 20%;
      margin: 0px;
      font-size: 15px !important;
      align-content: space-around;
      em {
        font-style: normal;
        font-size: 15px !important;
        display: block;
        font-weight: 500;
      }
    }
  }
}
</style>

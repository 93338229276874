<template>
  <v-row class="mt-4">
    <v-col :lg="12" :sm="12" :md="12">
      <v-card class="" v-if="form.message.active">
        <v-row dense>
          <v-card
            class="card-message d-flex flex-column justify-center pa-5"
            color=""
          >
            <section
              class="d-flex justify-center text-right font-weight-medium font-green text-head"
            >
              <v-icon x-large color="#388E3C">{{ form.message.icon }}</v-icon>
            </section>
            <v-card-subtitle class="text-center font-weight-medium">
              {{ form.message.msg }}
            </v-card-subtitle>
          </v-card>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "MessageView",
  computed: {
    ...mapState("autovistoria", ["form"]),
  },
  watch: {
    "form.currentStep": function (newValue, oldValue) {},
  },
};
</script>
<style scoped>
.card-message {
  width: 100%;
}
</style>

<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex lg12 sm12 xs12>
        <v-card class="app-card">
          <v-card-title class="app-card-title mb-0">
            <h4 class="text">
              <span>Detalhes do Pedido</span>
            </h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text
            class="app-card-content pl-0 pr-0 pb-0"
            v-if="detail.data"
          >
            <section class="status-area">
              <h4>
                <v-chip class="ma-2" color="success" outlined>
                  <strong class="mr-1">Número:</strong>
                  <span>{{ detail.data.id }}</span>
                </v-chip>
              </h4>
              <h4>
                <v-chip color="success" outlined>
                  <strong class="mr-1">Tipo Pedido:</strong>
                  <span>{{ detail.data.typeSolicitation.name }}</span>
                </v-chip>
              </h4>
              <h4>
                <v-chip color="success" outlined>
                  <strong class="mr-1">Situação:</strong>
                  <span>{{ detail.data.status | statusPedido }}</span>
                </v-chip>
              </h4>
            </section>
            <v-tabs color="success">
              <v-tab>Detalhe Pedido</v-tab>
              <v-tab>Acompanhamento</v-tab>
              <v-tab
                v-if="
                  detail.data.status !== statusPedido.AGUARDANDO_DOCUMENTACAO
                "
                >Pagamentos</v-tab
              >
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="mt-3">
                    <v-row>
                      <v-col
                        :lg="12"
                        :md="12"
                        :sm="12"
                        v-if="detail.data.placa"
                      >
                        <v-text-field
                          label="Veículo/Placa"
                          outlined
                          disabled
                          :value="detail.data.placa"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <DocumentsList :documentos="detail.data.documents" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <AcompanhamentoList v-if="detail.data && detail.data.id" />
              </v-tab-item>
              <v-tab-item
                v-if="
                  detail.data.status !== statusPedido.AGUARDANDO_DOCUMENTACAO
                "
              >
                <v-card flat>
                  <v-card-text class="mt-3">
                    <PaymentsList
                      :payments="detail.data.payments"
                      :group="detail.data.group"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <section class="isLoading" v-if="detail.isLoading">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </section>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DocumentsList from "./documentos/List";
import PaymentsList from "./pagamentos/List";
import DocumentosForm from "./documentos/Form";
import AcompanhamentoList from "./acompanhamentos/List";

import { StatusPedido } from "@/models/status-pedido.enum";
import { router } from "@/router";

export default {
  components: {
    DocumentsList,
    PaymentsList,
    AcompanhamentoList,
  },
  data: () => ({
    dialogCreateCi: {
      open: false,
      data: null,
    },
    formulario: {
      assunto: null,
      viproc: null,
    },
    statusPedido: StatusPedido,
    rules: {
      required: (value) => !!value || "Esse campo deve ser preenchido",
    },
  }),
  methods: {
    iniData() {
      const {
        params: { id },
      } = this.$route;
      this.getPedido(id);
    },
    doAnalyze() {
      const { id } = this.detail.data;
      this.updatePedido({ id, status: "EM_ANALISE" });
    },
    doCreateCI() {
      // const isValid = this.$refs.formulario.validate();

      // if (isValid) {
      //   this.createCI(this.formulario);
      //   this.closeDialog();
      // }
      this.createCI(this.formulario);
      this.closeDialog();
    },
    openDialog(item) {
      this.dialogCreateCi = { ...this.dialogCreateCi, open: true, data: item };
    },
    closeDialog() {
      this.dialogCreateCi = { ...this.dialogCreateCi, open: false, data: null };
      this.formulario = { ...this.formulario, assunto: null, viproc: null };
    },
    ...mapActions("pedido", { getPedido: "SHOW" }),
    ...mapActions("pedido", { showPaymentsGroup: "SHOW_PAYMENTS_GROUP" }),
    ...mapActions("pedido", { clearPedido: "CLEAR_DETAIL" }),
    ...mapActions("pedido", { updatePedido: "UPDATE" }),
    ...mapActions("pedido", { createCI: "CREATE_CI" }),
    ...mapActions("pedido", { showPedidosGroup: "SHOW_PEDIDOS_GROUP" }),
  },
  computed: {
    ...mapState("pedido", ["detail"]),
    ...mapState("autovistoria", ["form"]),
  },
  watch: {
    "detail.data": function (val) {
      if (val.typeSolicitation.id == 9) {
        this.showPaymentsGroup(val.group);
        this.showPedidosGroup(val.group);
      }
    },
    "form.message.active": function (val) {
      this.iniData();
      this.showPaymentsGroup(this.detail.data.group);
    },
  },
  mounted() {
    this.iniData();
  },
  destroyed() {
    this.clearPedido();
  },
};
</script>

<style lang="scss" scoped>
.isLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  margin: 0px;
}
.status-area {
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  text-align: center;
  justify-content: center;
  h4 {
    text-transform: uppercase;
    flex: 1;
    span {
      font-size: 15px;
    }
    strong {
      font-size: initial;
    }
  }
}
</style>

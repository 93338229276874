<template>
  <section>
    <p class="mt-3">
      Atenção: O pagamento via boleto demora até 48h úteis para ser debitado.
    </p>
    <v-btn
      depressed
      color="success"
      id="form-checkout__submit"
      type="button"
      block
      @click.prevent="doPayment()"
      >Finalizar Pagamento</v-btn
    >
  </section>
</template>

<script>
import { DEFAULT_EMAIL_PAYMENT } from "@/commons/config";
export default {
  name: "PaymentBoleto",
  props: {
    paymentCartaoIndendificationNumber: {
      type: String,
      required: true,
      default: "",
    },
    paymentCartaoIndendificationValue: {
      type: Number,
      required: true,
      default: 0,
    },
    idPedidos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    doPayment() {
      const formPaymets = {
        kind: "add",
        content: {
          solicitation_ids: this.idPedidos,
          type: "ticket",
          payment_data: {},
        },
      };
      this.$emit("doPayment", formPaymets);
    },
  },
};
</script>

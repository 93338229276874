<template>
  <section>
    <!-- {{ paymentsParent }} -->
    <v-data-table
      :headers="headers"
      :items="paymentsParent"
      hide-default-footer
      v-if="paymentsParent && paymentsParent.length"
    >
      <template v-slot:item.status="{ item }">
        {{  statusPayment[`${item.status}`] }}
      </template>
      <template v-slot:item.value="{ item }">
        {{ item.value | currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          depressed
          color="success"
          v-if="
            item.externalPaymentType === 'ticket' && item.status !== 'CANCELADO'
          "
        >
          <a
            :href="item.externalPaymentUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="white--text"
          >
            <v-icon>monetization_on</v-icon>Boleto
          </a>
        </v-btn>
        <span v-else>-</span>
      </template>
    </v-data-table>
    <PaymentsGroupForm
      :paymentProducts="paymentProducts"
      :paymentProductValue="paymentProductValue"
      :paymentProductOwnerIdentification="paymentProductOwnerIdentification"
      :idPedidos="idPedidosGroup"
      @doPayment="doPayment"
      v-else-if="PagamentosGroup != null && paymentsParent.length == 0"
    />

    <PagamentosForm v-else />
  </section>
</template>

<script>
import PagamentosForm from "./Form";
import PaymentsGroupForm from "@/components/PaymentGroup/PaymentForm.vue";
import { mapActions, mapState } from "vuex";
import { StatusPayment } from "@/models/status-payment.enum";
export default {
  name: "PaymentsList",
  components: {
    PagamentosForm,
    PaymentsGroupForm,
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
    group: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Número",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Grupo ID",
        align: "start",
        sortable: false,
        value: "group",
      },
      {
        text: "Valor",
        align: "start",
        sortable: false,
        value: "value",
      },
      {
        text: "Descrição",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
      },
      {
        text: "Ações",
        align: "start",
        sortable: false,
        value: "actions",
        width: "150px",
      },
    ],
    statusPayment: StatusPayment,
  }),
  methods: {
    doPayment(value) {
      this.doPayment(value);
    },
    ...mapActions("autovistoria", { doPayment: "doPayment" }),
  },
  computed: {
    ...mapState("pedido", ["detail"]),
    ...mapState("global", ["user"]),

    paymentsParent: {
      get: function () {
        return this.payments;
      },
    },
    PagamentosGroup: {
      get: function () {
        return this.group;
      },
    },
    paymentProducts: function () {
      const typeSolicitation = this.detail.data.pedidosGrupo.map((pedidos) => ({
        typeSolicitation: pedidos.type_solicitation,
      }));
      return typeSolicitation ? typeSolicitation : [];
    },
    paymentProductValue: function () {
      return this.detail.data.typeSolicitation
        ? Number(this.detail.data.typeSolicitation)
        : 0;
    },
    paymentProductOwnerIdentification: function () {
      return this.user ? this.user.cnpj : "";
    },
    idPedidosGroup: {
      get: function () {
        return this.detail.data.pedidosGrupo.map((pedidos) => pedidos.id);
      },
    },
    // idPedidos: function () {
    //   return this.pedidosGrupo.map((pedidos) => pedidos.id);
    // },
  },
};
</script>

<template>
  <div>
    <h4 class="title">Documentos</h4>

    <v-list subheader two-line>
      <v-list-item-group>
        <template v-for="(file, index) in documentos">
          <v-list-item :key="index" :dense="true" :selectable="false">
            <v-list-item-avatar @click.prevent.stop="doDownload(file)">
              <v-icon class="red white--text" dark v-text="'description'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="mt-3"
                v-if="file.typeDocument"
              >{{ file.typeDocument ? file.typeDocument.name : '-' }} </v-list-item-title>

              <v-list-item-subtitle v-text="`Arquivo: ${file.name}`"></v-list-item-subtitle>


              <v-list-item-subtitle>
                <v-chip
                  small
                  class="mt-1 mb-1"
                  v-if="file.status === 'NAO_AVALIADO'"
                >{{ statusAnexos[file.status] }}</v-chip>
                <v-chip
                  small
                  class="mt-1 mb-1"
                  :color="file.status === 'ACEITO' ? 'success' : 'error'"
                  v-else
                >{{ statusAnexos[file.status] }}</v-chip>
                <v-chip
                  small
                  class="mt-1 mb-1 ml-3"
                  color="success"
                  @click.prevent.stop="doDownload(file)"
                >
                  <v-icon size="15">file_download</v-icon>Baixar
                </v-chip>
                <v-chip
                  small
                  class="mt-1 mb-1 ml-3"
                  color="warning"
                  v-if="file.status === 'REJEITADO'"
                  @click.prevent.stop="openDialogUpload(file)"
                >
                  <v-icon size="15">edit</v-icon>Ajustar Arquivo
                </v-chip>
              </v-list-item-subtitle>
              <span v-if="file.status === 'REJEITADO' && file.reason" class="rejeicao-text">
                <strong class="strong">Motivo Rejeição:</strong>
                <p class="text-justify">{{ file.reason }}</p>
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="file.name + index" class="divider-item-list"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-dialog v-model="dialogUpload.open" width="500" v-if="dialogUpload.open">
      <v-form ref="form" lazy-validation class="app-form" @submit.prevent="doUploadDocumento">
        <v-card class="mb-0">
          <v-card-title class="app-card-title">Upload Documento - {{dialogUpload.documento.typeDocument.name}}</v-card-title>
          <v-card-text class="app-card-content">
            <!-- <Upload v-model="dialogUpload.file" :nameInput="1" /> -->
            <UploadFile
              v-model="dialogUpload.file"
              :nameInput="1"
              @change="doUploadDocumento($event, dialogUpload.documento)"
            />
            <section class="loading" v-if="dialogUpload.isLoading">
              <v-progress-circular indeterminate color="success"></v-progress-circular>
              <span>Enviando informações ...</span>
            </section>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="error" @click="closeDialogUpload">Fechar</v-btn>
            <!-- <v-btn depressed color="success" type="submit" :disabled="!dialogUpload.file">Enviar</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <DocumentosForm />
  </div>
</template>

<script>
import { StatusDocumento } from "@/models/status-documento.enum";
import { TipoDocumento } from "@/models/tipo-documento.enum";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import Upload from "./Upload";
import DocumentosForm from "./Form";
import UploadFile from "@/components/UploadFile";

import relativeTime  from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: "DocumentsList",
  components: {
    //Upload,
    UploadFile,
    DocumentosForm
  },
  props: {
    documentos: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    statusAnexos: StatusDocumento,
    tipoDocumento: TipoDocumento,
    dialogUpload: {
      open: false,
      documento: null,
      file: null,
      isLoading: false
    }
  }),
  methods: {
    // daysAgo(date) {
    //   //dayjs().from(dayjs(`${date}`).format('yyyy-mm-dd'))
    //   const updatedAt = dayjs(date).format('YYYY-MM-DD')
    //   console.log(updatedAt)
    //   return dayjs().to(updatedAt)
    // },
    openDialogUpload(item) {
      this.dialogUpload.open = true;
      this.$store.commit("pedido/SET_DOCUMENT_FORM", {
        typeDocumentKey: item.typeDocument.key,
        doc: {
          ...item,
          filename: null,
          type: null,
          size: 0,
          progress: 0,
          uploaded: false,
          error: false
        }
      });
      this.dialogUpload.documento = this.form.documents.find(d=>d.typeDocumentKey===item.typeDocument.key);
    },
    closeDialogUpload() {
      this.dialogUpload = {
        open: false,
        documento: null,
        file: null,
        isLoading: false
      };
    },
    async doUploadDocumento(event, doc) {
      this.dialogUpload.isLoading = true;

      const documento = {
        currentDocument: this.dialogUpload.documento,
        file: event.target.files[0]
      };
      await this.uploadDocument(documento);
      this.closeDialogUpload();
      this.loadPedido(this.detail.data.id)
    },
    doDownload(item) {
      this.downloadDocumento(item);
    },
    ...mapActions("pedido", {
      downloadDocumento: "DOWNLOAD_DOCUMENTO"
    }),
    ...mapActions("pedido", { uploadDocumentos: "UPLOAD_DOCUMENTOS_CRLV" }),
    ...mapActions("pedido", {
      uploadDocument: "uploadDocument"
    }),
    ...mapActions("pedido", {
      loadPedido: "SHOW"
    }),
  },
  computed: {
    ...mapState("pedido", ["form"]),
    ...mapState("pedido", ["detail"])
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold !important;
  border-bottom: solid 1px #c5c5c5;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-bottom: 5px;
}
.divider-item-list {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.rejeicao-text {
  display: block;
}
</style>
